import request from './ajax'

const orderUrl = '/api/runOrder/'

// 待抢单记录列表
export const runOrderList = (data) => request(orderUrl + 'list', 'post', { ...data })
// 抢单
export const runRob = (data) => request(orderUrl + 'rob', 'post', { ...data })
// 送达
export const runService = (data) => request(orderUrl + 'service', 'post', { ...data })
//订单详情
export const runOrderInfo = (data) => request(orderUrl + 'info', 'post', { ...data })
//申请改派
export const runOrderRewrite = (data) => request(orderUrl + 'rewrite', 'post', { ...data })
// 送达订单列表
export const runServiceOrder = (data) => request(orderUrl + 'service_order', 'post', { ...data })

